<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="shipping !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          no-close-on-backdrop
          ok-title="بله"
          @ok="DeleteShipping"
      >
        <span>آیا از حذف کردن این تعرفه باربری اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          cancel-title="بستن"
          centered
          no-close-on-backdrop
          ok-title="بروزرسانی تعرفه باربری"
          @ok.preventDefault()="UpdateShipping"
      >
        <b-row v-if="selectedShipping">
          <b-col cols="12">
            <b-form-group>
              <label for="email">نوع باربری</label>
              <v-select
                  v-model="selectedShipping.shippingTypeId"
                  :clearable="false"
                  :options="shippingTypes"
                  :reduce="name => name.shippingTypeId"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">استان</label>
              <v-select
                  v-model="selectedShipping.stateId"
                  :clearable="false"
                  :options="states"
                  :reduce="name => name.iranStateId"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">شهر</label>

              <v-select
                  v-model="selectedShipping.cityId"
                  :clearable="false"
                  :options="cities"
                  :reduce="name => name.iranCityId"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">قیمت (تومان)</label>
              <input v-model="selectedShipping.price" class="form-control" placeholder="100000 تومان" type="number">

            </b-form-group>
            <b-form-group>
              <label for="email">قیمت سفارش رایگان(تومان)</label>
              <input v-model="selectedShipping.priceLimit" class="form-control" placeholder="56465 تومان" type="number">

            </b-form-group>
          </b-col>

        </b-row>


      </b-modal>
      <b-modal
          id="modal-Add"
          cancel-title="بستن"
          centered
          no-close-on-backdrop
          ok-title="افزودن تعرفه باربری"
          @ok.preventDefault()="CreateShipping"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label for="email">نوع باربری</label>
              <v-select
                  v-model="newShipping.shippingTypeId"
                  :clearable="false"
                  :options="shippingTypes"
                  :reduce="name => name.shippingTypeId"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">استان</label>
              <v-select
                  v-model="newShipping.stateId"
                  :clearable="false"
                  :options="states"
                  :reduce="name => name.iranStateId"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">شهر</label>

              <v-select
                  v-model="newShipping.cityId"
                  :clearable="false"
                  :options="cities"
                  :reduce="name => name.iranCityId"
                  input-id="user-status"
                  label="name"
              />
            </b-form-group>
            <b-form-group>
              <label>قیمت</label>
              <input v-model="newShipping.price" class="form-control" placeholder="10000 تومان" type="number">

            </b-form-group>
            <b-form-group>
              <label>قیمت سفارش رایگان</label>
              <input v-model="newShipping.priceLimit" class="form-control" placeholder="10000 تومان" type="number">

            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <strong>تعرفه پیشفرض حمل و نقل</strong>
          <b-row class="mt-25">
            <b-col class="mb-2" lg="2" md="12">
              <label>قیمت (به تومان)</label>
              <input v-model="defaultShipping.item1" class="form-control" placeholder="10000 تومان" type="number">
            </b-col>
            <b-col class="mb-2" lg="2" md="12">
              <label>قیمت رایگان (به تومان)</label>
              <input v-model="defaultShipping.item2" class="form-control" placeholder="10000 تومان" type="number">
            </b-col>
            <b-col class=" d-flex align-items-center " lg="2" md="12">
              <b-button
                  @click="updateDefaultShipping"
                  variant="success"
              >
                ثبت
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="2" md="12">
              <b-button
                  class="w-100"
                  v-b-modal.modal-Add
                  variant="success"

              >
                افزودن تعرفه باربری جدید
              </b-button>
            </b-col>
            <b-col class="my-2 ml-auto" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
            </b-col>
          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="shipping"
            bordered
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            :sort-by.sync="sortBy"
            responsive
            show-empty
            striped
        >


          <template #cell(price)="data">

            <small>{{ Intl.NumberFormat('fa-IR').format(data.item.price) }} تومان</small>

          </template>
          <template #cell(priceLimit)="data">

            <small>{{ Intl.NumberFormat('fa-IR').format(data.item.priceLimit) }} تومان</small>

          </template>
          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="setSelectedShipping(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>

              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex flex-row"
                   @click="setSelectedShipping(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
            </div>

          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {CreateShipping, UpdateShipping, DeleteShipping, GetAllShipping, GetAllShippingType} from "@/libs/Api/shipping";
import {GetStates, GetCitiesByStateId} from "@/libs/Api/CityStates";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import helper from "@/libs/Helper";
import {GetDefaultShipping, SetDefaultShipping} from "@/libs/Api/SiteDynamic";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: 'لیست تعرفه باربری ها  - پنل ادمین انزا مد',
  name: 'shippingMain',
  data() {
    return {
      shipping: null,
      totalCount: null,
      shippingTypes: [],
      states: [],
      cities: [],
      sortBy: '',
      defaultShipping: null,
      showOverlay: false,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'shippingId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'shippingTypeName',
          label: 'نوع',
          sortable: true
        },
        {
          key: 'stateName',
          label: 'استان',
          sortable: true
        },
        {
          key: 'cityName',
          label: 'شهر',
          sortable: true
        },
        {
          key: 'price',
          label: 'قیمت',
          sortable: true
        },
        {
          key: 'priceLimit',
          label: 'قیمت رایگان',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

        // { key: 'parentId'},
      ],
      newShipping: {
        shippingTypeId: null,
        cityId: null,
        stateId: null,
        price: null,
        priceLimit: null,
        createDate: new Date(Date.now()),
      },
      currentPage: 1,
      perPage: 10,
      selectedShipping: null,

    }
  },
  async created() {
    await this.getAllShipping()
    await this.getAllShippingType()
    await this.GetStates();
    await this.getDefaultShipping()
  },
  components: {
    ClearSortsBtn,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {
    'selectedShipping.stateId': function (val) {
      this.GetCitiesByStateId(val)

    },
    'newShipping.stateId': function (val) {
      this.GetCitiesByStateId(val)
    },
    currentPage: function () {
      this.getAllShipping()
    }
  },

  methods: {
    async getDefaultShipping() {
      let _this = this
      let getDefaultShipping = new GetDefaultShipping(_this)
      await getDefaultShipping.fetch(function (content) {
        _this.defaultShipping = content
      }, function (error) {
        console.log(error)
      })
    },
    async updateDefaultShipping() {
      let _this = this
      let setDefaultShipping = new SetDefaultShipping(_this)
      let data = {
        price: _this.defaultShipping.item1,
        priceLimit: _this.defaultShipping.item2,

      }
      setDefaultShipping.setRequestParam(data)
      await setDefaultShipping.fetch(function (content) {
        _this.getDefaultShipping()
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تعرفه پشفرض بروزرسانی شد`,
          },
        })
      }, function (error) {
        console.log(error)
      })
    },
    async getAllShippingType() {
      let _this = this
      let getAllShippingType = new GetAllShippingType(_this)
      let data = {
        pageNumber: 1,
        count: 100
      }
      getAllShippingType.setParams(data)
      await getAllShippingType.fetch(function (content) {
        _this.shippingTypes = content.shippingTypes
      }, function (error) {
        console.log(error)
      })
    },
    async GetCitiesByStateId(val) {
      let _this = this
      let getCities = new GetCitiesByStateId(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        stateId: val === undefined ? '' : val
      }
      getCities.setParams(data)
      await getCities.fetch((content) => {
        _this.cities = content.cities
      }, (err) => {
        console.log(err)
      })
    },
    async GetStates() {
      let _this = this
      let getStates = new GetStates(_this)
      let data = {
        pageNumber: 0,
        count: 0
      }
      getStates.setParams(data)
      await getStates.fetch((content) => {
        _this.states = content.cities

      }, (err) => {
        console.log(err)
      })

    },
    async UpdateShipping() {
      let _this = this
      let updateShipping = new UpdateShipping(_this)
      _this.showOverlay = true
      updateShipping.setData(_this.selectedShipping)
      await updateShipping.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تعرفه باربری بروزرسانی شد`,
          },
        })

        _this.getAllShipping();
      }, function (error) {
        console.log(error)
      })
      _this.showOverlay = false

    },
    async CreateShipping() {
      let _this = this
      _this.showOverlay = true
      let createShipping = new CreateShipping(_this)
      createShipping.setData(_this.newShipping)
      await createShipping.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تعرفه باربری ساخته  شد`,
          },
        })
        _this.getAllShipping();
        _this.newShipping = {
          shippingTypeId: null,
          cityId: null,
          stateId: null,
          price: null,
          priceLimit: null,
          createDate: new Date(Date.now()),
        }

      }, function (error) {
        console.log(error)
      })
      _this.showOverlay = false
    },
    async DeleteShipping() {
      let _this = this
      _this.showOverlay = true

      let deleteShipping = new DeleteShipping(_this)
      deleteShipping.setParams({id: this.selectedShipping.shippingId})
      await deleteShipping.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تعرفه باربری  حذف شد.`,
          },
        })
        _this.getAllShipping();
      }, function (error) {
        console.log(error)
      })
      _this.showOverlay = false

    },
    setSelectedShipping(item) {
      this.selectedShipping = JSON.parse(JSON.stringify(item))
    },
    async getAllShipping() {
      let _this = this
      _this.showOverlay = true
      let getAllShipping = new GetAllShipping(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: 10
      }
      getAllShipping.setParams(data)
      await getAllShipping.fetch(function (content) {
        _this.shipping = content.shippings
        _this.totalCount = content.shippingsCount
      }, function (error) {
        console.log(error)
      })
      _this.showOverlay = false

    },
  },
}
</script>

<style scoped>

</style>
