import WebServiceRequest from '../Api/WebServiceRequest'

class GetStates extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Iran/GetAllIranStates')
  }
  setParams(data) {
    super.setRequestParam(data)
  }

}

class GetCitiesByStateId extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Iran/GetIranCitiesByStateId')
  }
  setParams(data) {
    super.setRequestParam(data)
  }

}


export {
  GetStates,
  GetCitiesByStateId
};
