import WebServiceRequest from '../Api/WebServiceRequest'

class GetDefaultShipping extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('SiteDynamicSetting/GetDefaultShipping')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class SetDefaultShipping extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('SiteDynamicSetting/UpdateDefaultShipping')
    }
    setData(data) {
        super.setRequestParamDataObj(data);
    }
}







export {
    GetDefaultShipping,
    SetDefaultShipping,
};
