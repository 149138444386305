import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllShippingType extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ShippingType/GetAll')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class CreateShippingType extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ShippingType/Create')
    }
    setData(data) {
        super.setRequestParamDataObj(data);
    }
}
class UpdateShippingType extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ShippingType/Update')
    }
    setData(data) {
        super.setRequestParamDataObj(data);
    }
}
class DeleteShippingType extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ShippingType/Delete')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class GetAllShipping extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Shipping/GetAll')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class CreateShipping extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Shipping/Create')
    }
    setData(data) {
        super.setRequestParamDataObj(data);
    }
}
class UpdateShipping extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Shipping/Update')
    }
    setData(data) {
        super.setRequestParamDataObj(data);
    }
}
class DeleteShipping extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Shipping/Delete')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    GetAllShippingType,
    CreateShippingType,
    UpdateShippingType,
    DeleteShippingType,
    GetAllShipping,
    CreateShipping,
    UpdateShipping,
    DeleteShipping,

};
